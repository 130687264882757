// ==================================================
// Variables
// ==================================================
w1200 = 'screen and (max-width: 1200px)'
w1024 = 'screen and (max-width: 1024px)'
w991 = 'screen and (max-width: 991px)'
w767 = 'screen and (max-width: 767px)'
w680 = 'screen and (max-width: 680px)'
w480 = 'screen and (max-width: 480px)'
w420 = 'screen and (max-width: 420px)'

.list {
	margin 0
	padding 0
	&__item {
		list-style none
		position relative
		font-size 24px
		margin-bottom 10px
		padding-left 25px
		@media w420 {
			font-size 18px
		}
		&:before {
			content ''
			display block
			width 7px
			height 7px
			position absolute
			left 0
			top 10px
			border-radius 50%
			background-color #e52128
		}
	}
}

.list-block {
	margin-top 40px
	&__item {
		text-align left
		&-ttl {
			font-size 30px
			color #e52128
			margin-bottom 10px
			@media w420 {
				font-size 22px
			}
		}
	}
}