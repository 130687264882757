.list {
  margin: 0;
  padding: 0;
}
.list__item {
  list-style: none;
  position: relative;
  font-size: 24px;
  margin-bottom: 10px;
  padding-left: 25px;
}
@media screen and (max-width: 420px) {
  .list__item {
    font-size: 18px;
  }
}
.list__item:before {
  content: '';
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 10px;
  border-radius: 50%;
  background-color: #e52128;
}
.list-block {
  margin-top: 40px;
}
.list-block__item {
  text-align: left;
}
.list-block__item-ttl {
  font-size: 30px;
  color: #e52128;
  margin-bottom: 10px;
}
@media screen and (max-width: 420px) {
  .list-block__item-ttl {
    font-size: 22px;
  }
}
